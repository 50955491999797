import React from "react"
import styled from 'styled-components';
import Layout from "../layouts";
import Hover from "../components/hover";
import Footer from "../components/footer";
import VideoMockup from "../components/videoMockup";
import Container from "../components/./container";
import BackgroundImage from "../components/image";
import AnimatedText from '../components/animatedText';
import ArrowDown from "../components/arrowDown";
import AnimatedImage from "../components/animatedImage";
import Seo from "../components/seo"

const Wrapper = styled.div`
.flux > div {
    padding-top: 100px;
}

.flux > div:first-child {
    padding-top: 20vh;
    padding-bottom: 5vh;
}

.flux > div:nth-child(2){
    padding-top: 0;
}

h1{
    text-align:left;
    text-transform: uppercase;
    letter-spacing: .5rem;
    font-size: 5rem;
    padding-bottom:2rem;
}

.wecreate2,
.wecreate3{
    position:absolute;
    top:0;
}

@media(max-width:512px){
    h1{
    letter-spacing: .3rem;
    font-size: 10vw;
}
}
`


// markup
const VirtualPopupStore = () => {

    return (
        <>
            <Layout>
                <Seo title="Virtual Pop-up Store - Vertual" />
                <Hover type="">
                    <main data-scroll data-scroll-id="update">
                        <ArrowDown />
                        <Wrapper>
                            <div className="flux">
                                <Container block position="intro" reversed direction="column">
                                    <AnimatedText tag="h1">Virtual Pop-Up Store</AnimatedText>
                                    <AnimatedText tag="p">
                                        We create virtual stores that mix together brand, content, architecture and digital, regardless of the constraints and limitations of reality with unlimited range of customizations.
                                    </AnimatedText>
                                </Container>
                                <VideoMockup video={"popupstore1"} fullpage />
                                <Container vh>
                                    <div className="mockupVideo">
                                        <VideoMockup video={"iphonepopupstore"} flexend />
                                    </div>
                                    <div className="description">
                                        <AnimatedText tag="h2">Memorable shopping experiences</AnimatedText>
                                        <AnimatedText tag="p">Customers can take a virtual walk around the store, interact with the environment, engage with the medias and the objects, zoom in and out from different angles to evaluate the details of the products, and make purchases in real time.</AnimatedText>
                                    </div>
                                </Container>
                                <VideoMockup video={"popupstore2"} />
                                <Container reversed>
                                    <div className="description">
                                        <AnimatedText tag="h2">Available on any web browser and device</AnimatedText>
                                        <AnimatedText tag="p">Our platform easily integrates an existing website to extend your e-commerce into an immersive shopping experience, supporting multi regions, languages and currency versions.</AnimatedText>
                                    </div>
                                    <div className="mockupVideo">
                                        <AnimatedImage><BackgroundImage filename={"popupstore_bag.jpg"} /></AnimatedImage>
                                    </div>
                                </Container>
                                <VideoMockup video={"ipadpopupstore"} />
                                <Container vh>
                                    <div className="mockupVideo">
                                        <VideoMockup video={"iphonepopupstorechat"} flexend />
                                    </div>
                                    <div className="description">
                                        <AnimatedText tag="h2">Virtual Shopping Assistant</AnimatedText>
                                        <AnimatedText tag="p">Our solutions integrate any live videos and chat features to the virtual environment and let Store associates interact with your customers in real time, so they can give them personalized advice, as if they were in-store.</AnimatedText>
                                        <AnimatedText tag="h2">Virtual Retail Analytics</AnimatedText>
                                        <AnimatedText tag="p">
                                            The analytics dashboard gives key insights and real time data about navigations, interaction and monetization at a user level. All data can be exported for even deeper analysis or exported to different systems.
                                        </AnimatedText>
                                    </div>
                                </Container>
                                <VideoMockup video={"popupstore3"} fullpage />
                                <Footer page="virtualshowroom" />
                            </div>
                        </Wrapper>
                    </main>
                </Hover>
            </Layout>
        </>
    )
}

export default VirtualPopupStore


